<template>
    <div  class="app">
    <div style=" height: 69px;
    padding: 0 0%;
    background-color: #fff;justify-content: flex-start;" class="erqiwu">
  <img style=" height: 60px;" src="@/assets/imges/90fe6e3a48949d2873e211e050690f1.png" alt="" />
    </div>
    <div style="" class="app-a">
 <BDhear :num="4"></BDhear>
        </div>
            <el-dialog
      title="文件预览"
      :visible.sync="centerDialogVisible"
      width="80%"
    >


      <!-- <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
  </span> -->
    </el-dialog>
      <!-- <div class="app-h">投保申请</div> -->
   <div style="background: #ffff;margin: 0px 8%;margin-top: 25px;padding-bottom: 25px;">
       <div class="app-xt">
        <div class="app-b">
          <div class="b-bbc">
            <div class="text item">
              <!-- <img
              src="../../../src/assets/imges/shen.png"
              class="a-img"
              alt=""
            /> -->
            </div>
            <!-- <a download="" href="https://www.hnzyxsbh.com/uploads/bhfiles/20220818/BH_202208181660826053.pdf">1234567</a> -->
            <div v-if="xianshi" class="public-kuang">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div class="kuang-txt">
                  <img
                    src="../../../src/assets/imges/pdf.png"
                    alt=""
                    class="pdf"
                    srcset=""
                  />
                  <div class="kuang-1">密文保函</div>
                </div>
          <div style="display: flex;align-items: center;justify-content: center;">
                  <div  class="kuang-2" @click="yxiazai">预览</div>
                  <div style="margin-left:10px ;" class="kuang-2" @click="xiazai">下载</div>

          </div>
              </div>
              <div style="margin-top: 40px">
                <div>保函文件：{{ wenname }}</div>

                <div style="margin-top: 10px">下载地址：{{ filepath }}</div>
                <div></div>
              </div>
            </div>
            <div v-if="xianshi2" class="public-kuang">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div class="kuang-txt">
                  <img
                    src="../../../src/assets/imges/pdf.png"
                    alt=""
                    class="pdf"
                    srcset=""
                  />
                  <div class="kuang-1">明文保函</div>
                </div>
                <div class="kuaqu" v-if="!mingwen">{{ baskdj }}</div>
               <div style="display: flex;align-items: center;justify-content: center;">
                  <div  v-if="smingwen" class="kuang-2" @click="yhuoqu">
                  预览
                </div>
                 <div style="  margin-left:10px ;" v-if="mingwen" class="kuang-2" @click="huoqu">
                  {{ namses }}huoqu
               
               </div>

                <div v-if="!mingwen" class="kuang-2">
                  <img
                    src="../../../src/assets/imges/jiazai.gif"
                    class="imgasd"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
            
            </div>
            <div v-if="dians">
              <img
                src="../../../src/assets/imges/baohanwen.png"
                class="public-ku"
                alt=""
                srcset=""
              />
            </div>
            
            <!-- <div v-if="tishi" class="text item itm">流程申请中，请耐心等待</div> -->
          </div>
          <!-- </el-card> -->
        </div>
      </div>

      <transition name="el-fade-in-linear"> </transition>
      <div v-if="!dians" class="butt butt-2">
        <div v-if="dian" @click="myinvoice" class="button-ii">下一步</div>
        <!-- <div v-else @click="myinvoices"  class="button-ii">下载开票</div> -->
        <el-button v-else type="primary" class="button-ii" disabled>{{
          tongbus
        }}</el-button>
      </div>

      <!-- <div class="butt butt-1">
        <el-button v-if="huanyaun" @click="Hybao" type="info" class="butt-ii">
          还原保函</el-button
        >
      </div> -->
   </div>
    </div>
   <!-- <div class="asdzong">
  <div style="    padding: 38px; border-bottom: 3px #efefef solid;"><img src="../../assets/login/lianxiwomen.png" class="asdimgh" alt="" srcset=""></div>
  <div style="margin-top: 20px; display:flex; flex-direction:column; align-items: center;"><div><img src="../../assets/login/tang.png" class="asdimgh" alt="" srcset=""></div>
  <div style="font-size: 14px;">业务联系人：唐先生</div>
   <div style="font-size: 14px;">联系电话：13140161000</div>
  </div>
   <div style="margin-top: 20px; display:flex; flex-direction:column; align-items: center;"><div><img src="../../assets/login/zhang.png" class="asdimgh" alt="" srcset=""></div>
  <div style="font-size: 14px;">技术联系人：张先生</div>
   <div style="font-size: 14px;">联系电话：13838271800</div>
  </div>
</div> -->
  </div>
</template>

<script>
import {
  companybhlist,
  companyst,
  bhprocess,
  restorenotice,
  baohannotice,
} from "@/api/user";
import { createcontract, filesign, bhfiledown, snfiledown } from "@/api/user";
// import download from 'downloadjs'
import BDhear from "../../components/BDhear.vue";
// import "./js/modernizr.js";
// import "./js/main"
export default {
      components: {
    // dinad,
    BDhear
  },
  data() {
    return {
      input: {
        name: "",
        names: "",
        kai: "",
      },
      tabPosition: "right",
      tab: "left",
      dialogImageUrl: "",
      centerDialogVisible:false,
      dialogVisible: false,
      disabled: false,
      baohanno: "TBaoHanXDCS2022071818473558051",
      dian: true,
      dians: false,
      surbing: "../../../src/assets/imges/baohanwen.png",
      huany: false,
      tishi: false,
      xianshi: true,
      huanys: false,
      huanyaun: false,
      yi: true,
      kai: true,
      ban: true,
      bai: true,
      quan: false,
      erci: false,
      duan: "- - - - -",
      duanl: "———",
      active: 0,
      bankaccount: "",
      tong: true,
      tongbus: "申请中...",
      tabPosition: "right",
      tab: "left",

      dialogImageUrl: "",
      cion: "",
      dialogVisible: false,
      unify_code: "",
      billstatus: "",
      ztype: "",
      filepath: "未获取", //密文
      mfilepath: "未获取", //明文
      epath: "https://www.hnzyxsbh.com",
      xianshi2: true,
      applyno: "",
      namses: "获取",
      panduan: "",
      mfile: "",
      wenname: "未获取",
      mwenname: "未获取",
      timer: null, // 定时器名称
      timers: null, // 定时器名称
      mingwen: true,
      baskdj: "生成保函模板中...",
smingwen:false,
      message: "132",
    };
  },
  created() {
    this.applyno = sessionStorage.getItem("applyno");
    this.baohanno = sessionStorage.getItem("baohanno");

    this.bhproces();
  },
  beforeDestroy() {},
  methods: {
    async ding() {
      var that = this;
      var apps = this.applyno;
      this.timers = setInterval(async function () {
        that.bhproces();
        if (that.ztype == 1 && that.billstatus == 2) {
          that.$once("hook:beforeDestroy", () => {
            clearInterval(that.timers);
          });
        }
        // if(that.billstatus==1){
        //   that.dian = false;
        // that.tongbus = "已发送";
        // return;
        // }
      }, 1000);
    },
    async companybhlist() {
      var cion = this.baohanno;
      const { data } = await companybhlist(cion);
      // console.log(data);
    },
    yhuoqu(){
            let link = document.createElement("a");
      link.style.display = "none";
      link.style.download=""
      link.target = "_blank";
      link.href = this.mfilepath;
      link.setAttribute("download", ".pdf");
      // +'.pdf' 通过不同的后缀名下载不同的文件
      //这里下载的是pdf格式的文件，拿到接口里的res也必须是pdf的文件流
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href)   //URL.revokeObjectURL() 静态方法用来释放一个之前通过调用 URL.createObjectURL() 创建的已经存在的 URL 对象。当你结束使用某个 URL 对象时，应该通过调用这个方法来让浏览器知道不再需要保持这个文件的引用了。
      document.body.removeChild(link);
// console.log("明文预览");
    },
    async bhproces() {
      // console.log(132);
      var that = this;
      var cion = this.applyno;
      const { data } = await bhprocess(cion); //获取状态
      // console.log(data);
      // "ztype=>0:密文，1:等待还原，2:还原完成;billstatus=>0:未领取,1:申请中,2:已发放"
      var cd = data.data.billstatus; //开票状态
      // var zt=null
      this.wenname = data.data.filepath;
      var vvc = data.data.mfilepath;
      if (data.data.mfilepath !== null) {
        var bbc = vvc.split("/");
        this.mwenname = bbc[4];
        this.mfilepath = this.epath + data.data.mfilepath; //明文
      }

      this.wenname = this.wenname.split("/")[4];
      this.filepath = this.epath + data.data.filepath; //pdf
      var mmfilepath = this.epath + data.data.mfilepath; //明文
      this.mfile = data.data.mfilepath;
      this.panduan = data.data.mfilepath;
      this.ztype = data.data.ztype; //保函
      if (this.ztype === 0) {
        this.namses = "等待开标";
        this.mingwen = true;
      }
      if (this.ztype === 1) {
        this.namses = "获取";
        this.mingwen = true;
      }
      if (this.ztype === 2) {
        this.namses = "下载";
        this.smingwen=true
        this.mingwen = true;
        this.pand();
      }

      if (cd === null || cd === 0) {
        this.dian = true;
        // this.entryUserInfo();
        return;
      }
      if (cd === 1) {
        this.dian = false;
      }
      if (cd === 2) {
        this.dian = false;
        this.tongbus = "已发送";
        return;
      }
    },
    pand() {
      if (this.mfile == null) {
        this.namses = "获取";
        this.mingwen = true;
      } else {
        console.log(123);
        this.namses = "下载";
        this.mingwen = true;
      }
    },
    async huoqu() {
      if (this.namses == "等待开标") {
        this.$alert("等待开标，请稍后。", "提示", {
          confirmButtonText: "确定",
          customClass: "messageasd",
          type: "warning",
          callback: (action) => {
            window.location.reload();
            // this.invoicenoti("app");
            // this.$router.push({
            //   name: "public",
            //   params: {},
            // });
          },
        });
        return;
      }
      if (this.namses == "下载") {
        let url = this.mfilepath;
        var bbc = {
          applyno: this.applyno,
          type: 1,
        };
        const { data } = await snfiledown(bbc);
        this.mingpdf(data.data)
        //       download(
        //      url
        // );
        // console.log(url);
        // let link = document.createElement("a");
        // link.style.display = "none";
        // link.target = "_blank";
        // link.href = url;
        // link.setAttribute("download", ".pdf");
        // // +'.pdf' 通过不同的后缀名下载不同的文件
        // //这里下载的是pdf格式的文件，拿到接口里的res也必须是pdf的文件流
        // document.body.appendChild(link);
        // link.click();
        return;
      } else {
        // this.erci=true;
        this.mingwen = false;
        // this.bhprocess();
        this.entryUserInfo();
      }
    },
    myinvoice() {
      var bbc = this.applyno;
      this.dian = false;
      var that = this;
      this.tishi = true;
      // console.log(132);
      //  setTimeout(function(){
      //     that.bbca()   // 关闭窗口
      //     }, 2000);
      that.$once("hook:beforeDestroy", () => {
        clearInterval(that.timer);
      });
      this.$router.push({
        // name: "myinvoice",
        name:"defray",
        params: {
          bbc,
        },
      });
    },

    entryUserInfo() {
      this.quan = true;

      var that = this;

      for (let i = 0; i < 5; i++) {
        this.timer = setTimeout(
          function () {
            that.nextx(i);
          },
          i * 6000,
          i
        );
      }
      // var timename = setTimeout(function(){that.bhproces()},3000);
    },
    async nextx(i) {
      var bbc = this.unify_code;
      var cion = this.applyno;
      var tijiao = {
        applyno: cion,
        type: 2,
      };
      var that = this;
      console.log("i", i);
      if (i == 4) {
        // this.bhproces()
        // this.timer = null;
        this.mingwen = true;
        return;
      }
      if (i == 0) {
        const { data } = await createcontract(tijiao);
        // console.log(data);
        var bbc = 5;
        if (data.length == 0) {
          for (let i = 0; i <= 4; i++) {
            // console.log(i);
            setTimeout(
              function () {
                bbc--;
                that.baskdj = "加载中：" + bbc + "s";
              },
              i * 1000,
              i
            );
          }
        } else {
          // console.log(2);
          this.baskdj = "生成保函模板中...";
        }
        return;
      }
      if (i == 1) {
        // this.next1();
        const { data } = await filesign(tijiao);
        var bbc = 5;
        if (data.length == 0) {
          for (let i = 0; i <= 4; i++) {
            // console.log(i);
            setTimeout(
              function () {
                bbc--;
                that.baskdj = "加载中：" + bbc + "s..";
              },
              i * 1000,
              i
            );
          }
        } else {
          // console.log(2);
          this.baskdj = "保函签章中...";
        }

        return;
      }
      if (i == 2) {
        const { data } = await bhfiledown(tijiao);
        console.log(data);
        var bbc = 5;
        this.baskdj = "保函下载中...";
        return;
      }
      if (i == 3) {
        console.log("步骤三");

        var tong = this.applyno;
        const { data } = await restorenotice(tong);
        // console.log(data);
        this.bhproces();
        //  window.clearTimeout(this.timer);
        return;
      }
    },
    yxiazai(){
      this.centerDialogVisible=true
      return
      let link = document.createElement("a");
      link.style.display = "none";
      link.style.download=""
      link.target = "_blank";
      link.href = this.filepath;
      link.setAttribute("download", ".pdf");
      // +'.pdf' 通过不同的后缀名下载不同的文件
      //这里下载的是pdf格式的文件，拿到接口里的res也必须是pdf的文件流
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href)   //URL.revokeObjectURL() 静态方法用来释放一个之前通过调用 URL.createObjectURL() 创建的已经存在的 URL 对象。当你结束使用某个 URL 对象时，应该通过调用这个方法来让浏览器知道不再需要保持这个文件的引用了。
      document.body.removeChild(link);
// console.log("密文预览");
    },
    async xiazai() {
      let url = this.filepath; //  download(url)
      var bbc = {
        applyno: this.applyno,
        type: 0,
      };
      const { data } = await snfiledown(bbc);
      this.xiazpdf(data.data)

    },
    dataURLtoBlob(dataurl) {
      var bstr = atob(dataurl);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: "pdf" });
    },
    xiazpdf(e){
      var that=this
// res.data   就是后台返回的base64的 文件流
  let URL = this.dataURLtoBlob(e);
  var reader = new FileReader();
  reader.readAsDataURL(URL);
  reader.onload = function (e) {
    // 兼容IE
    if (window.navigator.msSaveOrOpenBlob) {
      var bstr = atob(e.target.result.split(",")[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var blob = new Blob([u8arr]);
    
      window.navigator.msSaveOrOpenBlob(blob, that.wenname);
    } else {
      // 转换完成，创建一个a标签用于下载
      var a = document.createElement('a');
      a.download = that.wenname;
      a.href = e.target.result;
      a.setAttribute("id", "export")
      document.getElementById("purchaseManage").append(a); // 修复firefox中无法触发click
      a.click();
      document.getElementById("export").remove();
    }
  }
    },
        mingpdf(e){
      var that=this
// res.data   就是后台返回的base64的 文件流
  let URL = this.dataURLtoBlob(e);
  var reader = new FileReader();
  reader.readAsDataURL(URL);
  reader.onload = function (e) {
    // 兼容IE
    if (window.navigator.msSaveOrOpenBlob) {
      var bstr = atob(e.target.result.split(",")[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var blob = new Blob([u8arr]);
    
      window.navigator.msSaveOrOpenBlob(blob, that.mwenname);
    } else {
      // 转换完成，创建一个a标签用于下载
      var a = document.createElement('a');
      a.download = that.mwenname;
      a.href = e.target.result;
      a.setAttribute("id", "export")
      document.getElementById("purchaseManage").append(a); // 修复firefox中无法触发click
      a.click();
      document.getElementById("export").remove();
    }
  }
    },
    tuibao() {
      var bbc = this.baohanno;
      this.$router.push({
        name: "mycompany",
        params: {
          bbc,
        },
      });
    },

    bbca() {
      this.dians = false;
      this.tishi = false;
      this.xianshi = false;
      this.huany = true;
    },
    myinvoices() {
      this.dians = true;
      this.huanyaun = true;
      this.xianshi = false;
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
  },
};
</script>
<style scoped>
html {
  width: 100vw;
}
/deep/ .el-dialog.ajhcjahsd {
  width: 16.6875rem !important;
}
.asdzong{
  position: fixed;
  right: 20px;
  bottom: 10%;
  width: 200px;
  display:flex;
  border: 1px #c5c5c5 solid;
  border-top :3px   tomato solid;
  flex-direction:column;
  /* justify-content: center; */
      align-items: center;
      padding-bottom: 20px;
}
.asdimgh{
width: 60px;
    height: 60px;
}
.quan {
  /* background: rgb(128, 128, 128, 1); */
  position: fixed;
  top: -10rem;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.kuaqu {
  margin-right: -100px;
}
.cdion-2 {
  position: relative;
}
.cdion-3 {
  position: absolute;
  font-size: 0.75rem;
  top: -1.5625rem;
  left: 0.5625rem;
}
.imgasd {
  width: 1.625rem;
  height: 1.625rem;
  margin-top: -0.3125rem;
}
.cion-fe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app {
  /* padding: 0 20%; */
}
/deep/ .el-steps--vertical {
  height: 80%;
}
.el-icon-loading {
  font-size: 20px;
}
.cion-bu {
  margin-top: 80px;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 200px;
  border-radius: 0.6875rem;
  background: #069cff;
  color: #fff;
}
.cion-bu1 {
  margin-top: 80px;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 200px;
}
.felx {
  display: flex;
}
.felx-1 {
  padding: 0 5px;
  height: 80px;
  display: flex;
  line-height: 128px;
  font-size: 30px;

  /* align-items:center; */
  color: rgb(170, 167, 167);
}
.felx-2 {
  padding: 0 5px;
  height: 80px;
  display: flex;
  line-height: 128px;
  font-size: 30px;
  font-weight: 900;
  /* align-items:center; */
  color: #069cff;
}
.yanse {
  /* padding-left: .1875rem; */
  padding-top: 3.2px;
  /* width: 24.2px; */
  height: 24.2px;
  /* color: #0a308f; */

}
.yanse1 {
  margin-top: 0.1875rem;
  width: 40.2px;
  height: 40.2px;
}
.cdion-txt {
  font-size: 0.8125rem;
  margin-bottom: 1.25rem;
}
.dion {
  line-height: 85px;
  font-size: 15px;
  padding: 0px 0.3125rem;
}
.a-imgs {
  padding-top: 22.4px;
  width: 24.2px;
  height: 24.2px;
}
.namei {
  margin-top: 10px;
  height: 20%;
  margin-left: 70%;

  /* height: 100%;
    margin-left: 70%; */
}
/* /deep/ .el-step__icon.is-icon{
    border-radius: 50%;
    border: 2px solid;
    border-color: inherit;
    w
} */
.app-xt {
  padding: 0 8%;
}
.cdion-1 {
  width: 48px;
  height: 48px;
  font-size: 1.9375rem;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background: #069cff;
  border: 0.125rem solid #069cff;
  margin-bottom: 20px;
  border-radius: 320px;
  /* background-image:url("../../../src/assets/imges/1fff.png"); */
  /* background-size: 100%,100%; */
}
.cdion-2 {
  width: 48px;
  height: 48px;
  font-size: 1.9375rem;
  text-align: center;
  line-height: 48px;
  border: 0.125rem solid #f6f7f8;
  color: #fff;
  background: #f6f7f8;
  margin-bottom: 20px;
  border-radius: 320px;
}
.b-bbc {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.indec {
  display: inline-block;
  width: 420px;
  padding: 50px;
  margin-top: -15%;
  height: 35%;
  padding-bottom: 10px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0rgba (0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding-bottom: 30px;

  /* display: flex;
  flex-direction: column;
    justify-content: space-between; */
}
.name {
  margin-bottom: 20px;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name-jin {
  width: 100%;
  height: 100%;
  background: #dedede;
}
.uppder {
  padding-right: 12px;
}
.input-flex {
  display: flex;
  margin-top: 10px;
}
.input-1 {
  width: 200px !important;
  width: 113px;
  /* height: 20px !important; */
}
.app-h {
  padding-left: 1.6875rem;
  width: 100%;
  height: 2.6875rem;
  line-height: 2.6875rem;
  background: #1890ff;
  font-size: 1.0625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .el-input__inner {
  margin-left: 14px !important;
  height: 27px !important;
}

.text {
  font-size: 14px;
}

.item {
  padding: 8px 0;
}

.box-card {
  width: 480px;
}
.flex-txt {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0101;
}
.flex-txt1 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 100px;
  text-align: right;
}
.abc {
  padding-left: 24px;
}
.app-a {
  display: flex;
  /* margin-top: 1.75rem; */
  position: relative;
}
.a-left {
  width: 50%;
  height: 3.0625rem;
  display: flex;
  line-height: 3.125rem;
  align-content: center;
  justify-content: center;
  color: #069cff !important;
  /* background: #cdebff; */
  border: 0.0625rem solid #069cff;
  /* border-right: 0; */
  /* border-radius: 0.5625rem 0rem 0rem 0.5625rem; */
  font-size: 17px !important;
  font-family: Microsoft YaHei;
  font-weight: 400 !important;
  color: #ffffff;
}
.a-img {
  padding-top: 0.85rem;
  padding-right: 0.9375rem;
  width: 1.25rem;
  height: 1.25rem;
}
.aa-img {
  position: absolute;
  height: 48.96px;
  margin-top: 0.0625rem;
  padding: 0;
  left: 45%;
}
.a-imgs {
}
.a-right {
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 3.125rem;
  width: 50%;
  height: 3.0625rem;
  margin-top: 1px;

  background: #069cff;
  /* border-radius: 0.4375rem; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 550;
  color: #ffffff;
}
.app-b {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 2.8125rem;
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding: 0 30%;
  /* align-content: space-around;
  justify-content: space-between; */
}
.app-c {
  width: 100%;
  margin-top: 1.875rem;
}
.wohi-txt {
  font-size: 0.75rem;
  padding-left: 0.75rem;
}
.app-b2 {
  display: flex;
  line-height: 1.5rem;
  font-size: 0.8125rem;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 1.625rem;
}
.b-txt {
  width: 18.5rem;
  height: 1.5rem;
  flex: 1;
  background: #f3f3f3;
  border: 0.0625rem solid #dedede;
  padding-left: 0.375rem;
  line-height: 1.5rem;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.c-img {
  width: 30%;
  height: 100%;
}
.c-txt {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.txt-1 {
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-2 {
  margin-top: 1.0625rem;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: 0.75rem;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: 0.9375rem;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  margin-top: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.butt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-i {
  margin-top: 3rem;
  width: 20%;
  height: 5.5%;
  padding: 0.3125rem 0;
  line-height: 1.875rem;
  border-radius: 1.25rem;
  background: #069cff;
  text-align: center;
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}
/* 上传 */
</style>
<style scoped>
html {
  width: 100vw;
}
.app {
  background: #fff;
  /* padding: 0 20%; */
}
.pdf {
  width: 50px;
  height: 50px;
}
.itm {
  margin-top: 1.1875rem;
}
.app-xt {
  padding: 0 8%;
}
.b-bbc {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.butt-1 {
  margin-top: 0.625rem;
  margin-bottom: 0.9375rem;
}
.butt-2 {
  /* margin-top: 5.625rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.kuang-1 {
  font-size: 1.1625rem;
  line-height: 2.3125rem;
  margin-left: 1.25rem;
  margin-top: 0.375rem;
  font-family: Microsoft YaHei;
  font-weight: 800;
  color: #333333;
}
.kuang-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}
.kuang-2 {
  width: 4.125rem;
  height: 1.375rem;
  line-height: 1.375rem;
  text-align: center;

  /* border-radius: 20px; */
  background: #069cff;
  border: 0.0625rem solid #069cff;
  font-size:1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 0.3125rem 0.5125rem;
  color: #fff;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uppder {
  padding-right: 0.75rem;
}
.input-flex {
  display: flex;
  margin-top: 0.625rem;
}
.input-1 {
  width: 12.5rem !important;
  width: 7.0625rem;
  /* height: 1.25rem !important; */
}
.public-kuang {
  margin-top: 25px;
    width: 700px;
    height: 160px;
    padding: 1.2125rem 1.25rem;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  background: #ffffff;
  box-shadow: 0rem 0rem 0.1875rem 0rem rgba(0, 0, 0, 0.15);
}
.public-kuangs {
  /* margin-top: 3.125rem; */
  width: 399px;
  height: 58px;
  padding: 0.3125rem 1.25rem;

  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0rem 0rem 0.1875rem 0rem rgba(0, 0, 0, 0.15);
}
.public-ku {
  margin-top: 1.375rem;
  width: 443.8px;
  height: 250px;
  margin-bottom: 1.25rem;
}
.app-h {
  padding-left: 27px;
  /* width: 100%; */
  height: 43px;
  line-height: 43px;
  background: #1890ff;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.el-input__inner {
  margin-left: 0.875rem !important;

  height: 1.6875rem !important;
}

.text {
  font-size: 1rem;
}

.item {
  padding: 0.5rem 0;
}

.box-card {
  width: 30rem;
}
.flex-txt {
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0101;
}
.flex-txt1 {
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 6.25rem;
  text-align: right;
}
.abc {
  padding-left: 1.5rem;
}
.app-a {
  display: flex;
  /* margin-top: 28px; */
  position: relative;
}
.a-left {
  width: 50%;
  height: 49px;
  display: flex;
  line-height: 50px;
  align-content: center;
  justify-content: center;
  color: #069cff !important;
  /* background: #cdebff; */
  border: 1px solid #069cff;
  /* border-right: 0; */
  /* border-radius: 9px 0px 0px 9px; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.a-img {
  padding-top: 13.6px;
  padding-right: 15px;
  width: 63.6px;
  height: 63.6px;
}
.aa-img {
  position: absolute;
  height: 3.06rem;
  margin-top: 1px;
  padding: 0;
  left: 45%;
}
.a-right {
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 50px;
  width: 50%;
/* margin-top: 1px; */
  height: 49px;
  margin-top: 0.0625rem;
 border: 1px solid #069cff;
  background: #069cff;

  /* border-radius: 7px; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400 !important;
  color: #ffffff;
}
.app-b {
  /* margin-top: 10px; */
  font-size: .95rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 0px;
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding: 0 30%;
  /* align-content: space-around;
  justify-content: space-between; */
}
.app-c {
  width: 100%;
  margin-top: 30px;
}
.app-b2 {
  display: flex;
  line-height: 24px;
  font-size: 13px;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 26px;
}
.b-txt {
  width: 296px;
  height: 24px;
  flex: 1;
  background: #f3f3f3;
  border: 1px solid #dedede;
  padding-left: 6px;
  line-height: 24px;
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.c-img {
  width: 30%;
  height: 100%;
}
.c-txt {
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.txt-1 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-2 {
  margin-top: 17px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: 15px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.butt {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  /* flex-direction:column; */
}
.button-ii {
  margin-top: 80px;
  width: 25%;
  height: 5.5%;
  padding: 10px 0;
  line-height: 1.275rem;
  /* border-radius: 8px; */
  border: 0.0625rem solid #069cff;
  text-align: center;
  background: #069cff;
  font-size: 1.275rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
}
.butt-ii {
  width: 20%;
  height: 5.5%;
  border-radius: 20px;
  border: 0.0625rem solid #069cff;
  text-align: center;
  background: #069cff;
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
}
.sa-img{
      padding-top: 13.6px;
    padding-right: 15px;
    width: 20px;
    height: 20px;
}
/* 上传 */
</style>
<style >
.messageasd {
  width: 267px !important;
  margin-top: -15.875rem;
}
</style>